<template>
    <div class="content">
        <div class="header">
            <div class="logo">
                <img class="img" :src="headerData.logoImageUrl" alt="">
            </div>
            <div class="title">{{headerData.titleName}}</div>
            <div class="tips">
                <div>
                    The ultimate
                </div>
                <div>
                   indicator health analysis
                </div>
            </div>
        </div>
        <div class="line"></div>
    </div>
</template>
<script>
export default {
    props: {
        headerData: Object 
    }
}
</script>
<style lang='scss' scoped>

.content{
    padding-top: 30px;
    .header{
        display:flex;
        justify-content:space-between;
        width: 95%;
        height:30px;
        margin: 0 auto;
        margin-left: 30px;
        .logo{
            width: 68px;
            height: 18px;
        }
        .title{
            font-size:18px;
            font-weight: 600;
            color:#1386B0;
        }
        .tips {
            letter-spacing: 0.1px;
            color:#1386B0;
            text-align:right;
            margin-right: 10px;
        }
    }
    .line{
        width:100%;
        height:3px;
        margin:0 auto;
        margin-left: 30px;
        background: linear-gradient(180deg, #1386B0 0%, rgba(19,134,176,0) 100%);
        margin-top: 10px;
    }
}
</style>